export const headlineText =
  "software engineer & musician";
export const musicianSummaryText1 =
  "For more than a decade Spencer has served as a first-call percussionist and timpanist with the Omaha and Kansas City Symphonies. He has performed with the Grand Rapids Symphony, Indianapolis Symphony Orchestra, Hawaii Symphony Orchestra, and the New World Symphony of Miami Beach, FL. He is on the roster of the Omaha Chamber Music Society and is a co-founder of Omaha Percussion Group. He has accompanied a diverse range of artists, including Yo-Yo Ma, Lyle Lovett, Joyce DiDonato, Guster, Branford Marsalis, Keith Urban, Ben Folds, and Bernadette Peters.";
export const musicianSummaryText2 =
  "Originally from Broken Arrow, Oklahoma, Spencer holds a BA in Music from Oklahoma State University and an MM in Percussion Performance with post-graduate studies in orchestral percussion from the University of Missouri–Kansas City Conservatory. His primary percussion teachers include Christopher McLaurin, Wayne Bovenschen, and Tom McGillen, and he is an alumnus of The Music Academy of the West, the National Orchestral Institute, the Texas Music Festival, and the Cloyd Duff Timpani Masterclass.";
export const musicianSummaryText3 =
  "Spencer maintains a small private studio of highly motivated students, both in person and online via Zoom. Please send an email to inquire.";
export const softwareSummaryText =
  "Adept and driven, Spencer began his tech career with Harvard’s CS50 before enrolling in the computer science program at Creighton University. In his second year of school, he spent eight months learning on the job as an engineering intern, working as full-stack web developer. After the internship, he transferred to a full-time role as an iOS developer, and discovering a love for building mobile applications. An ardent proponent of delightful, simple, and accessible apps, he is proficient in native iOS development, React, React Native, RESTful API practices, and is pursuing the AWS Certified Developer–Associate certification.";
export const educatorSummaryText =
  "Spencer is adjunct professor of percussion at the University of Nebraska at Omaha and maintains a private studio of highly motivated high school students. From 2016-2021, Spencer directed percussion studies at Omaha Conservatory of Music, where he grew the percussion studio from zero to twenty students in five years. His students routinely placed in All-State ensembles, earned college scholarships, and won acceptance into prestigious programs such as Interlochen and Boston University-Tanglewood Institute. Additionally, Spencer has served on the faculties of Iowa Western Community College and Dordt University, as well as on the percussion staff of Broken Arrow (OK) High School’s The Pride of Broken Arrow marching band.";
